import React from 'react';

import './style.scss';
import Helmet from './helmet';
import Midsection from './midsection';
import Footer from './footer';

const Layout = ({ children }) => (
	<div style={{height: '100vh', width: '100vw'}}>
		<Helmet />		
		<Midsection />
		{/* <Footer /> */}
	</div>
);

export default Layout;
