import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import {
	FaLinkedin,
	FaEnvelope
} from 'react-icons/fa';

import './style.scss';

const Midsection = () => (
	<StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
			linkedIn
			email
          }
        }
      }
	`}
	render={data => (
	<div>
		<div className="columns is-gapless is-multiline">			
			<div className="column is-two-fifths is-hidden-touch"></div>
			<div className="column content-column">
				<div>
					<p className="text-main has-text-left animated bounceInLeft">I can build the <br/><span className="text-highlight">Website or Application</span> <br/> you always imagined</p>
					<br/>
					<br/>
					<p className="text-secondary has-text-right	animated bounceInRight">I have knowledge in <br/>technologies that can help <br/><span className="text-highlight">your business grow</span></p>
					<br/>
					<br/>
					<p className="text-normal has-text-centered	animated bounceInUp">Do not hesitate to contact me</p>
					<br/>
					<p className="contact-container has-text-centered animated bounceInUp">
						<a href={data.site.siteMetadata.linkedIn}><FaLinkedin color="#f5cdaa" /></a>
						<a href={`mailto: ${data.site.siteMetadata.email}`}><FaEnvelope  color="#f5cdaa" /></a>
					</p>
				</div>
			</div>
			<div style={{ position: 'absolute', bottom: '0', left: '0'}}>
				<span style={{ color: '#CCC', fontSize: '10px' }}>Photo by <a href="https://unsplash.com/@anniespratt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noopener noreferrer" style={{ color: '#CCC', textDecoration: 'underline' }}>Annie Spratt</a> on <a href="https://unsplash.com/s/photos/working?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noopener noreferrer" style={{ color: '#CCC', textDecoration: 'underline' }}>Unsplash</a></span>
			</div>
		</div>
	</div>
)}
/>
)

export default Midsection;
